import React from "react"
import styled from "styled-components"

export const PagerContainer = styled.div`
  padding: 10px;
  font-size: 13px;
  line-height: 20px;
  > :first-child::after {
    content: "   -------   ";
    white-space: pre;
    letter-spacing: -1.5px;
  }
`

const Pager = ({ index, total, ...props }) => (
  <PagerContainer {...props}>
    <span>{index}</span>
    <span>{total}</span>
  </PagerContainer>
)

export default Pager
