import React from "react"
import { urlFor } from "../../util/url"
import useMedia from "use-media"
import { size } from "../../util/devices"
import * as S from "./styled"
import { navigate } from "gatsby"
import BrandCard from "../BrandCard"

const BrandCarousel = ({
  brands,
  height = "480px",
  heightDesktop = "525px",
  onItemClick,
  ...props
}) => {
  const isDesktop = useMedia({ minWidth: size.tablet })

  onItemClick = index =>
    navigate(urlFor("DatoCmsBrand", brands[index].slug), {
      state: { modal: true },
    })

  return (
    <div className="carousel-container" {...props}>
      <S.Container>
        {brands.map((brand, i) => (
          <BrandCard
            height={isDesktop ? heightDesktop : height}
            parallax={false}
            objectFit="cover"
            brand={brand}
            key={brand.name}
            clickable={true}
            onBrandClick={() => onItemClick(i)}
          />
        ))}
      </S.Container>
    </div>
  )
}

export default BrandCarousel
